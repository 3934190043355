<template>
  <main>
    <Loading v-if="isLoading"></Loading>
    <div v-else>
      <div class="flex justify-between mt-5">
        <h2 class="text-2xl font-bold page-title">Terminal Retrieval</h2>
      </div>
      <div class="flex justify-between items-end my-5">
        <div class="">
          <input
            type="text"
            class="inline-block px-3 ring-2 outline-none ring-blue-500 rounded-sm w-80 text-sm py-2"
            name=""
            placeholder="Search Business name"
            id=""
            v-model="search"
          />
          <button
            @click="handleSearch"
            class="uppercase shadow ml-2 text-sm bg-blue-900 text-white hover:bg-blue-700 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
          >
            Search
          </button>
          <button
            @click="handleSearchReset"
            class="uppercase shadow ml-2 text-sm bg-red-900 text-white hover:bg-red-700 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
          >
            Reset
          </button>
        </div>
      </div>
      <div class="flex bg-blue-50 p-5 mt-10">
        <div class="flex-2 inline-block time-filter">
          <p>From:</p>
          <input
            type="date"
            class="inline-block border-2 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.startDate"
          />
        </div>
        <div class="flex-2 inline-block time-filter">
          <p>To:</p>
          <input
            type="date"
            class="inline-block border-2 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.endDate"
          />
        </div>
        <div class="flex mt-5">
          <button
            @click="fetchDatedRecords"
            class="block uppercase shadow bg-green-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-3 rounded"
          >
            Filter
          </button>
          <button
            @click="clearDateFilter"
            class="block uppercase shadow bg-red-800 hover:bg-red-700 ml-2 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-3 rounded"
          >
            Reset
          </button>
        </div>
      </div>
      <div class="flex justify-end mt-14">
        <div class="">
          <select
            type="date"
            class="inline-block border-2 px-2 rounded-sm mr-2 px-3 py-2 bg-none"
            id=""
            v-model="terminalType"
          >
            <option value="">Filter by Terminal Type</option>
            <option v-for="(filter, i) in filters" :key="i" :value="filter">
              {{ filter }}
            </option>
          </select>
        </div>
        <div class="" v-if="terminalType != ''">
          <button
            @click="filterByTerminalType"
            class="block uppercase shadow bg-blue-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs p-3 rounded"
          >
            Filter
          </button>
        </div>
        <div class="" v-if="terminalType != ''">
          <button
            @click="clearFilterByTerminalType"
            class="block uppercase shadow bg-red-800 ml-2 hover:bg-red-700 focus:shadow-outline focus:outline-none text-white text-xs p-3 rounded"
          >
            Clear
          </button>
        </div>
      </div>
      <div class="mt-3" style="height: 600px; overflow: auto">
        <table class="shadow-lg bg-white w-full relative border-collapse">
          <tr>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              S/N
            </th>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              OEM
            </th>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Terminal Type
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Terminal Model
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Terminal Serial
            </th>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Business name
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Email<br /><small
                >Email of the person who retrieved the terminal</small
              >
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Date and Time
            </th>

            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Terminal Status (Retrieved)
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Reason for retrieval
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Terminal Condition
            </th>
          </tr>
          <tr v-for="(terminal, i) in filterRetrievedTerminals" :key="i">
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ i + 1 }}
            </td>
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ terminal.oemName }}
            </td>
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ terminal.terminalType }}
            </td>
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ terminal.model }}
            </td>
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ terminal.serialNumber }}
            </td>
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ terminal.businessName }}
            </td>
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ terminal.loggedByEmail }}
            </td>
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ terminal.retrievalDate }}
            </td>
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ terminal.status }}
            </td>
            <td class="border px-8 py-3 text-xs capitalize">
              {{ terminal.retrievalReason }}
            </td>
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ terminal.condition }}
            </td>
          </tr>
        </table>
      </div>
      <div class="mt-3">
        <button
          @click="prevPage(currentPage)"
          :class="
            currentPage === 1
              ? 'bg-blue-50 text-black'
              : 'bg-blue-900 text-white'
          "
          class="uppercase shadow ml-3 hover:bg-blue-600 focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-8 rounded"
        >
          &laquo; Prev
        </button>

        <button
          v-if="GET_TMS_RETRIEVED_TERMINALS.data.length > 99"
          @click="nextPage(currentPage)"
          class="float-right uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
        >
          Next &raquo;
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import Loading from "./general-components/Loading.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import moment from "moment";
import { GET_USER_DATA } from "../browser-db-config/localStorage";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      isDisabled: false,
      search: "",
      accounts: null,
      currentPage: 1,
      totalPages: 0,
      dateFilter: false,
      filter: "",
      filters: ["mPOS", "Android", "Linux"],
      terminalTypeFilter: false,
      terminalType: "",
    };
  },
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(["GET_TMS_RETRIEVED_TERMINALS"]),
    filterRetrievedTerminals() {
      return this.GET_TMS_RETRIEVED_TERMINALS.data;
    },
  },
  async mounted() {},
  async created() {
    const d = new Date();
    this.dateData = {
      startDate: new Date(d.setDate(d.getDate() - 7)).toJSON().split("T")[0],
      endDate: new Date().toJSON().split("T")[0],
      creationDate: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
    };
    await this.fetchRetrievedTerminals();
  },
  methods: {
    ...mapActions(["FETCH_RETRIEVED_TMS_TERMINALS"]),
    async fetchRetrievedTerminals() {
      this.isLoading = true;
      this.currentPage = 1;
      const payload = {
        search: this.search,
        page: this.currentPage,
        dateFilter: this.dateFilter,
        date: this.dateData,
        terminalTypeFilter: this.terminalTypeFilter,
        terminalType: this.terminalType,
      };
      try {
        await this.$store.dispatch("FETCH_RETRIEVED_TMS_TERMINALS", payload);
        this.isLoading = false;
      } catch (error) {}
    },

    async prevPage(currentPage) {
      console.log(currentPage);
      if (currentPage === 1) return;
      console.log(currentPage);
      this.isLoading = true;
      const payload = {
        search: this.search,
        page: this.currentPage - 1,
        dateFilter: this.dateFilter,
        date: this.dateData,
        terminalTypeFilter: this.terminalTypeFilter,
        terminalType: this.terminalType,
      };
      let res = await this.$store.dispatch(
        "FETCH_RETRIEVED_TMS_TERMINALS",
        payload
      );
      if (res.status) {
        this.isLoading = false;
        this.currentPage -= 1;
      }
    },
    async nextPage() {
      this.isLoading = true;
      const payload = {
        search: this.search,
        page: this.currentPage + 1,
        dateFilter: this.dateFilter,
        date: this.dateData,
        terminalTypeFilter: this.terminalTypeFilter,
        terminalType: this.terminalType,
      };
      let res = await this.$store.dispatch(
        "FETCH_RETRIEVED_TMS_TERMINALS",
        payload
      );
      if (res.status) {
        this.isLoading = false;
        this.currentPage += 1;
      }
    },
    async handleSearch() {
      this.isLoading = true;
      this.currentPage = 1;
      const payload = {
        search: this.search,
        page: this.currentPage,
        dateFilter: this.dateFilter,
        date: this.dateData,
        terminalTypeFilter: this.terminalTypeFilter,
        terminalType: this.terminalType,
      };
      let res = await this.$store.dispatch(
        "FETCH_RETRIEVED_TMS_TERMINALS",
        payload
      );
      this.isLoading = false;
      if (!res.status) {
        alert("Could not find " + this.search);
      }
    },
    async handleSearchReset() {
      if (this.search == "") return;
      this.search = "";
      this.fetchRetrievedTerminals();
    },
    async fetchDatedRecords() {
      this.isLoading = true;
      this.dateFilter = true;
      this.currentPage = 1;
      const payload = {
        search: this.search,
        page: this.currentPage,
        date: this.dateData,
        dateFilter: this.dateFilter,
        date: this.dateData,
        terminalTypeFilter: this.terminalTypeFilter,
        terminalType: this.terminalType,
      };
      let res = await this.$store.dispatch(
        "FETCH_RETRIEVED_TMS_TERMINALS",
        payload
      );
      this.isLoading = false;
      if (!res.status) {
        alert(this.message);
      }
    },
    async clearDateFilter() {
      this.dateFilter = false;
      const d = new Date();
      this.dateData = {
        startDate: new Date(d.setDate(d.getDate() - 7)).toJSON().split("T")[0],
        endDate: new Date().toJSON().split("T")[0],
        creationDate: new Date().toJSON().split("T")[0],
        startTime: "00:00",
        endTime: this.moment(d).format("HH:mm"),
      };
      await this.fetchRetrievedTerminals();
    },
    async filterByTerminalType() {
      this.isLoading = true;
      this.terminalTypeFilter = true;
      this.currentPage = 1;
      const payload = {
        search: this.search,
        page: this.currentPage,
        date: this.dateData,
        dateFilter: this.dateFilter,
        date: this.dateData,
        terminalTypeFilter: this.terminalTypeFilter,
        terminalType: this.terminalType,
      };
      let res = await this.$store.dispatch(
        "FETCH_RETRIEVED_TMS_TERMINALS",
        payload
      );
      this.isLoading = false;
      if (!res.status) {
        alert(this.message);
      }
    },
    async clearFilterByTerminalType() {
      if (this.terminalType == "") return;
      this.terminalType = "";
      this.terminalTypeFilter = false;
      this.fetchRetrievedTerminals();
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
  position: sticky;
  top: 0;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
input:where([type="checkbox"][role="switch"]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  color: inherit;
  font-size: inherit;
  width: 2em;
  height: 1em;
  box-sizing: content-box;
  border: 1px solid;
  border-radius: 1em;
  vertical-align: text-bottom;
  margin: auto;
}

input:where([type="checkbox"][role="switch"])::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  box-sizing: border-box;
  width: 0.7em;
  height: 0.7em;
  margin: 0 0.15em;
  border: 1px solid;
  border-radius: 50%;
  background: currentcolor;
}

input:checked {
  background-color: #253985;
}
input:where([type="checkbox"][role="switch"]):checked::before {
  left: 1em;
  background-color: #fff;
}

input:where([type="checkbox"][role="switch"]):disabled {
  opacity: 0.4;
}
</style>
